




import { computed, defineComponent } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'WisdomClassRoom',
  components: {
    WisdomClassroomDesk: () => import('./desk.vue'),
    WisdomClassroomPhone: () => import('./phone.vue')
  },
  setup() {
    const wisdomType = computed(() =>
      AppModule.device === DeviceType.Desktop
        ? 'WisdomClassroomDesk'
        : 'WisdomClassroomPhone'
    )
    return { wisdomType }
  }
})
